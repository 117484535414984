import React from "react"
import styled from "styled-components"
//import ReactGA from "react-ga"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/CustomerSegmentHero"
import CustomerSegmentValueProps from "../components/CustomerSegmentValueProps"
import CustomerSegmentCarousel from "../components/CustomerSegmentCarousel"
import CustomerSegmentCenterTestingBlurb from "../components/CustomerSegmentCenterTestingBlurb"
import CustomerSegmentQuestion1 from "../components/CustomerSegmentQuestion1"
import CustomerSegmentApp from "../components/CustomerSegmentApp"
import { HCM_FEATURES } from "../lib/constants";


import Case1 from "../images/case_thera.jpg"
import Case2 from "../images/case_gabriel.jpg"

import PC1 from "../images/hcm-slider1.jpeg";
import PC2 from "../images/hcm-slider2.jpeg";
import PC3 from "../images/hcm-slider3.jpeg";
import PC4 from "../images/hcm-slider4.jpeg";
import PC5 from "../images/hcm-slider5.jpeg";

import HeroBackground from "../images/hcm-hero-bg.jpg"
import HcmDescription from "../images/hcm-description.jpg";
import { sizes } from "../lib/layout"

import Screen1 from "../images/hcm-mobile-screen1.jpeg";
import Screen2 from "../images/hcm-mobile-screen2.jpeg";

const list = [
  "HCM Core",
  "Payroll",
  "Talent ",
  "Recruiting",
  "Learning"
]

const arrayOfImages = [Case1, Case2]

const arrayOfQuotes = [
  {
    quote:
      "Transforming Hire to Retire Experience with The Oracle HCM Cloud",
    background:
      "The HR departments face multiple pressures to serve in a more strategic role that can have greater impact on mission delivery. Success requires an enterprise technology solution that connects talent management and HR processes, end to end.",
  },
  {
    quote: "Why Choose mivors for Oracle HCM Suite?",
    background:
      "Oracle HCM provides a consistent experience across devices, enables one source of truth for HR data to improve decision-making, and empowers you with market-leading innovation to address your needs today and into the future Also, the operations’ leaders seek financial integration to specialized applications for maintenance, manufacturing, and logistics and we are fulfilling this critical business requirement for transportation departments and local Governments. We understand the critical need for large cities, counties, and states to track and manage compliance around Minority Business Enterprise and Disadvantage Business Enterprise procurements. Transportation organizations must comply with Federal grants and effectively manage their impacts on financials and projects. County procurement solutions must address court systems, property taxes, and other unique needs.",
  },
]

const HCM = props => {
  const {
    location: { pathname },
  } = props

  return (
    <Layout pathName={pathname}>
      <SEO
        title="HCM"
        keywords={[
          `potency tester`,
          `cannabis potency testing`,
          `THCa tester`,
          `THC tester`,
          `harvest`,
          `cbd tester`,
          `microdosing`,
          `edibles recipes`,
          `cannabutter`,
          `marijuana`,
        ]}
      />
      <Hero
        bg={HeroBackground}
        title="Want mivors To Do Your HR Team’s Heavy Lifting?"
        description="Transforming your HR for the digital future? Mivors can deliver the expertise to take your Human Capital Management (HCM) to the Cloud. As your trusted partner for a successful Oracle HCM Cloud transition, we support you from start to finish."
      />
        <CustomerSegmentValueProps
          bgColor="#39B54A"
          features={HCM_FEATURES}
        />
        <CustomerSegmentCarousel
          img={HcmDescription}
          title="<strong>Transforming Hire to Retire Experience with The Oracle HCM Cloud</strong>"
          description="The HR departments of state and local governments, transportation, healthcare, and educational systems face multiple pressures to serve in a more strategic role that can have greater impact on mission delivery. Success requires an enterprise technology solution that connects talent management and HR processes, end to end."
        />
        <CustomerSegmentQuestion1
          title="Unlock the potential of your workforce"
          descriptions={[
            "Taking care of your employees and managing your business as efficiently as possible are goals that most organizations share. One way to achieve both is to implement an HR system to help you manage payroll, benefits and performance management. Oracle HCM Cloud is one example.",
            "Whether you’re implementing this system for the first time or migrating to Oracle HCM Cloud from Oracle E-Business Suite (EBS) or PeopleSoft, you’ll want a trusted partner to help you with that implementation."
          ]}
        />
        <CustomerSegmentCenterTestingBlurb
          bgColor="#29A03A"
          title="Supporting All Oracle HCM Cloud Modules"
          description="Using proven Oracle implementation methods and certified consultants, we offer your business the breadth of knowledge and well-rounded approach you require for a successful migration. We are able to support the following Oracle HCM Cloud Modules:"
          images={[PC1, PC2, PC3, PC4, PC5]}
          content={
            <>
              <p>
                  <ul>
                    {
                      list.map(li => (
                        <li key={li}>{li}</li>
                      ))
                    }
                  </ul>
                  For those organizations looking to maintain an on-prem solution while also managing certain functionalities in the Cloud, we provide Multi-Cloud solutions as well. This is a great solution if you’re not ready to move your HCM apps completely to the Cloud, while still taking advantage of modules with the functionality you need now.
              </p>
            </>
          }
        />
        <CustomerSegmentApp
          title="Objectives and Key Results Integrated in your HCM system"
          description="We deliver Oracle HCM integrarted with Icloud-ready employees productivity solutions which enable us to deliver unique employee's experience"
          appStoreLink=""
          googlePlayLink=""
          direction="ltr"
          images={[Screen1, Screen2]}
        />
    </Layout>
  )
}

export default HCM
